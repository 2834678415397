import DbBackupsEdit from './DbBackupsEdit';
import WebUniversal, { WUExtendPropsType } from '../WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { useEffect, useRef } from 'react';

const DbBackups = (props: any) => {
    /** api服务id */
    const apiId = 'dbBackups';

    const ref = useRef<any>();
    /** 后端数据defaultService */
    let defaultService: any = '';
    /** 刷新 */
    let initData: any;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            // const res = await fetchApi.GET(defaultService,
            //     {
            //         apiId: 'configs',
            //         apiVariable: 'system',
            //         apiExtend: 'getNodeValue',
            //         apiData: {
            //             nodeId: 'companyFilePath',
            //             privatePar: false
            //         }
            //     });
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'backupName',
        },
        {
            title: '数据库',
            dataIndex: 'dbSystemId',
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '数据库备份',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: DbBackupsEdit,
        showLogger: {
            loggerSingleDisplay: true,
            logger: { id: 'systemLogger' }
        },
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default DbBackups;
